import { useCallback } from 'react'
import { CryptoInvoiceStatus, Blockchain } from '@ppay/definitions'
import { usePolling as usePollingLib } from '@ppay/client'
import { useParams } from 'react-router-dom'

import { useDispatch } from '../../useDispatch'
import { useSelector } from '../../useSelector'
import { getInvoiceCryptoAction } from '../../../actions'

const getIntervalBlockchain: Record<Blockchain, number> = {
  [Blockchain.BITCOIN]: 1000 * 60 * 5,
  [Blockchain.ETHEREUM]: 1000 * 30,
  [Blockchain.BINANCE_SMART_CHAIN]: 1000 * 30,
  [Blockchain.TRON]: 1000 * 15,
  [Blockchain.POLYGON]: 1000 * 30
}

export const usePayPolling = () => {
  const getState = useSelector((state) => state.invoice.crypto.get)
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()

  const status = getState.data!.status

  const interval =
    status === CryptoInvoiceStatus.PENDING
      ? getIntervalBlockchain[getState.data!.amount!.blockchain]
      : 5000

  const shouldPoll = [
    CryptoInvoiceStatus.CREATED,
    CryptoInvoiceStatus.REGISTERED,
    CryptoInvoiceStatus.PENDING
  ].includes(status)

  usePollingLib(
    useCallback(() => dispatch(getInvoiceCryptoAction(id)), []),
    shouldPoll,
    interval
  )
}
